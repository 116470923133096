export const aboutUsEng = () =>
  navigator.clipboard.writeText(`
Justt is the result of several bottles of red wine on a balcony in Port d'Andratx on Mallorca. There, Yann and Anatol - friends from school days who took different paths after graduating from high school - exchange views about their frustrating online media consumption. The next morning, they find scribbles about a platform on the table. \n
The idea won't let them go. They decide to invest in a prototype and sink a lot of money into having the thing built cheaply in India. \n
A colleague at work put Yann in touch with Deniz, who had set up the digital agency RollingRabbits in Pristina, Kosovo. Intro: "When Deniz delivers something, everyone's jaw drops."We talk to Deniz on the phone, tell him about the idea. And then hear: nothing. \n
A week passes. Another. In the third week, Deniz sends a download link: 285 MB of data. We open the files. And see: Justt. And it looks amazing. The guy designed half the app, unpaid. "The idea is great. It inspired me. I worked through a few nights," is his answer. \n
Six months later - by which time several coders from Pristina and Skopje were already working on Justt - we fly to Kosovo for the first time. Deniz becomes a partner. \n
Yann is a techie. Anatol a journalist. Deniz a designer. We still need someone for the business. The next tip comes from BR host Christoph Süß, whom Anatol knows: "Call Felix. He's part of the family and does something with start-ups." Anatol calls. Turns out that Felix is the former strategy director at Sky and, as a former investment banker, evaluates start-up portfolios. Felix immediately believes in the idea. A short time later, Felix becomes a partner. The founding team is in place. \n  
Since then, our talented coder-team has been growing in Kosovo. We founded Justt AG with a top-class supervisory board. Our MVP is in the Appstore. And we recently added a top-notch saleswoman, Anne Hufnagel, who is developing the business and knocking down open doors. \n
But the essence of this story is this: that scribble on the balcony in Port d'Andratx was not utopia. Because we are actually building it now, the platform that makes many things different and many things better. Because we are creators ourselves and understand what you are missing out there. And we are users ourselves who understand what annoys you out there: trash, clickbait, manipulation.\n \n
Justt. \n
Join us – and be part of the better story.`);

export const aboutUsDe = () =>
  navigator.clipboard.writeText(`
Justt ist das Ergebnis mehrerer Flaschen Rotwein auf einem Balkon in Port d’Andratx auf Mallorca. Dort tauschen sich Yann und Anatol – Freunde aus Schulzeiten, die nach dem Abi unterschiedliche Wege eingeschlagen hatten – über ihren frustrierenden Online-Medienkonsum aus. Am nächsten Morgen liegen Kritzeleien zu einer Plattform auf dem Tisch. \n
Die Idee lässt die beiden nicht mehr los. Sie beschließen, in einen Prototyp zu investieren und versenken viel Geld dabei, das Ding günstig in Indien bauen zu lassen. \n
Yann bekommt von einem Arbeitskollegen den Kontakt zu Deniz zugesteckt, der in Pristina (Kosovo) die Digitalagentur „RollingRabbits“ aufgebaut hat. Intro: „Wenn der was liefert, fällt allen erstmal die Kinnlade runter.“ Wir telefonieren mit Deniz, erzählen ihm von der Idee. Und hören dann: nichts. \n
Eine Woche vergeht. Noch eine. In der dritten schickt Deniz einen Download-Link: 285 MB Daten. Wir öffnen die Files. Und sehen: Justt. Und es sieht großartig aus. Der Typ hat unbezahlt die halbe App designt. „Die Idee ist super. Hat mich inspiriert. Ich habe ein paar Nächte durchgearbeitet“, ist seine Antwort. \n
Ein halbes Jahr später – zu dem Zeitpunkt arbeiten bereits mehrere Coder:innen aus Pristina und Skopje an Justt – fliegen wir das erste Mal in den Kosovo. Deniz wird Partner. \n 
Yann ist Techie. Anatol Journalist. Deniz Designer. Uns fehlt noch eine/r fürs Business. Der nächste Tipp kommt von BR-Moderator Christoph Süß, mit dem Anatol beim Kaffee sitzt: „Ruf Felix an. Der gehört zur Familie und macht was mit Startups“. Anatol ruft an. Stellt sich raus: Felix ist der ehemalige Strategiedirektor von Sky und evaluiert als früherer Investmentbanker Startup-Portfolios. Felix glaubt sofort an die Idee. Wenig später wird Felix Partner, das Gründerteam steht. \n
Seitdem wächst unser talentiertes Entwickler-Team im Kosovo. Wir haben die Justt AG mit hochkarätigem Aufsichtsrat gegründet. Unser MVP ist im Appstore. Und mit Anne Hufnagel ist seit kurzem eine hochkarätige Vertriebsmanagerin dabei, die das Geschäft entwickelt und offene Türen einrennt. \n
Das Wesentliche an dieser Geschichte ist jedoch: Diese Kritzelei auf dem Balkon in Port d’Andratx war keine Utopie. Denn wir bauen sie jetzt tatsächlich, die Plattform, die vieles anders und vieles besser macht. Weil wir selbst Creators sind und verstehen, was Euch da draußen fehlt. Und selbst Nutzer:innen sind, die verstehen, was Euch da draußen nervt: Trash, Clickbait, Manipulation. \n \n
Justt. \n
Join us – and be part of the better story.`);
